<!--正常授信 上传图片-->
<template>
  <div class="upload">
    <div class='upload-header' v-if="uploadType!='qita'">
              <span>不会操作？点击查看</span>
              <span class="header-text" @click="showMadel">操作指引<i></i></span>
    </div>
    <div class='list'>
          <div class="imgBox" v-for="(item, index) in imgData" :key="index">
            <img :src="item" alt="" />
            <span @click="del(index)"><img src="./img/delete.png" alt="" /></span>
          </div>
          <div class="uploadImg" @click="selectImg">
            <img src="./img/upload.png" alt="" />
          </div>
    </div>
    
    <div class="submit" @click="submit">提交</div>
    <!--芝麻分 截图弹窗 -->
     <Modal
            :show="shutBack"
            @cancel="gocancel"
            :showCancel="true"
            class="upload-modal"
            :title="modalTitle"
            v-if="uploadType!='qita'" 
      >
      
         <div class='upload-mians' v-if="uploadType=='zhima'">
                <img class="ios-img"  src='./img/f-img.png' alt=""/>
            </div>
            <div class='upload-mians' v-if="uploadType=='shebao'">
                <img class="ios-img-sb"  src='./img/sb-img.png' alt=""/> 
            </div>
            <div class='upload-mians' v-if="uploadType=='gongjijin'">
                <img class="ios-img-gjj"  src='./img/gjj-img.png' alt=""/>
            </div>
        <div class='upload-btn' @click="modalBtn">我知道了</div>
      </Modal>
  </div>
</template>
<script>
import { toRefs, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import APP from "@/utils/App";
import axios from "axios";
import API from "../../server/api";
import { dealImage } from "../../utils/index";
import Modal from '@/components/Modal/Modal.vue'
export default {
  components: {
        Modal
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      fileList: [],
      fileName: "",
      fileType: "",
      num: 0,
      uploadName: "",
      uploadType: "",
      creditNo: "",
      userId: "",
      imgUrl: "",
      imgList: [],
      imgData: [], // 图片路径
      shutBack: false,//弹窗
      modalTitle:'如何操作截图'
    });
    //操作指引
   const showMadel=()=>{
      console.log(776)
      state.shutBack=true
    }
    //选择照片
    const selectImg = () => {
      if (!APP.BROWSER.android) {
        //ios上传方式
        APP.iOS_PICKPHOTO(async (res) => {
          console.log(res, "ios选择照片");
          if (res.code == 0) {
            if (res.response.images) {
              // console.log(res.response.images[0]);
              let imgArr = [];
              for (var i = 0; i < res.response.images.length; i++) {
                let response = await dealImage(
                  "data:image/jpg;base64," + res.response.images[i],
                  800
                );
                imgArr.push(response);
              }
              uploadOSS(imgArr);
            }
          }
        });
      } else {
        APP.PHOTO_CAMERA_MORE(async (res) => {
          // console.log(res,'00000');
          if (res.code == 0) {
            if (res.response.images) {
              let imgArrs = [];
              for (var i = 0; i < res.response.images.length; i++) {
                let responses = await dealImage(
                  "data:image/jpg;base64," + res.response.images[i],
                  800
                );
                imgArrs.push(responses);
              }
              uploadOSS(imgArrs);
            }
          }
        });
      }
    };
    const uploadOSS = async (file) => {
      // console.log(file, "file");
      for (var i = 0; i < file.length; i++) {
        const fileKey = "LRD-FILE";
        const result = await API.uploadOss({
          fileName: "jpg",
          fileType: fileKey,
        });
        // console.log(base64ToFile(file), "9999999999");
        if (result.code == 200) {
          const { host, accessId, dir, policy, signature } = result.result;
          const data = new FormData();
          data.append("key", dir);
          data.append("policy", policy);
          data.append("OSSAccessKeyId", accessId);
          data.append("success_action_status", "200");
          data.append("Signature", signature);
          data.append("file", base64ToFile(file[i]));
          const baseUrl = host;
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
          });
          axios({
            url: baseUrl,
            method: "post",
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => {
              if (res.status == 200) {
                const imgUrl = `${baseUrl}${dir}`;
                console.log(imgUrl, "图片地址");
                state.imgData.push(imgUrl);
              }
            })
            .catch((e) => {
              Toast.fail("图片上传失败，请刷新页面重试");
            });
        }
      }
    };
    const base64ToFile = (dataUrl, name) => {
      const arr = dataUrl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // console.log(File,'0000');
      return new File([u8arr], name + ".jpg", { type: mime });
    };
    // 上传图片
    const uploadFile = async (data) => {
      const imgFile = await API.uploadFile(data);
      if (imgFile) {
        Toast.success("上传成功");
        setTimeout(() => {
          APP.BACK(-1);
        }, 1000);
      }
    };
    // 获取图片列表
    const getPhotoList = async () => {
      const photos = await API.getPhotoList({
        creditNo: state.creditNo,
        type: state.uploadType,
      });
      console.log(photos.echoList, "图片");
      state.imgData = photos.echoList;
    };
   
    const gocancel = () => {
      console.log('关闭弹窗')
      state.shutBack = false
     
    }
    const modalBtn=()=>{
       console.log('我知道了')
      state.shutBack = false
    }
    onMounted(() => {
      APP.SET_TITLE("上传截图");
      const routeInfo = route.query.jsParam;
      const arr = routeInfo.split("&");
      const obj = {};
      for (var i = 0; i < arr.length; i++) {
        const aa = arr[i].split("=");
        obj[aa[0]] = aa[1];
      }
      state.routeInfo = obj;
      console.log(state.routeInfo, "route444");
      state.uploadName = state.routeInfo.everyName;
      state.uploadType = state.routeInfo.type;
      getPhotoList();
      const {dataType,datalength}=state.routeInfo
      if(dataType=='jietu'){
        
        if(datalength>0){
           state.shutBack = false
        }else{
          console.log(state.imgData,'888')
          state.shutBack = true
        }

      }
      if(state.uploadType=='shebao'){
        console.log('shebao')
          state.modalTitle="如何操作社保截图"
      }else if(state.uploadType=='gongjijin'){
         console.log('gongjijin')
          state.modalTitle="如何操作公积金截图"
      }
      state.creditNo = localStorage.getItem("creditNo");
      state.userId = localStorage.getItem("userId");
     
    });
    const del = (n) => {
      state.imgData.splice(n, 1);
    };
    const submit = () => {
      const imgModel = {};
      imgModel.imgData = state.imgData;
      imgModel.type = state.uploadType;
      if (state.imgData.length === 0) {
        Toast.fail("请先上传附件!");
        return false;
      } else {
        uploadFile({
          creditNo: state.creditNo,
          imgModel: imgModel,
          userId: state.userId,
        });
      }
    };

    return {
      ...toRefs(state),
      submit,
      del,
      selectImg,
      gocancel,
      modalBtn,
      showMadel
    };
  },
};
</script>
<style lang="less" src="./index.less" scoped></style>

